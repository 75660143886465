// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__2t6Je {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Header_backToHome__OZWnm {
  text-decoration: none;
  color: blue;
  font-size: 1em;
}

.Header_backToHome__OZWnm:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Header.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".header {\n  width: 100%;\n  padding: 10px;\n  background-color: #fff;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.backToHome {\n  text-decoration: none;\n  color: blue;\n  font-size: 1em;\n}\n\n.backToHome:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__2t6Je`,
	"backToHome": `Header_backToHome__OZWnm`
};
export default ___CSS_LOADER_EXPORT___;
