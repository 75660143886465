// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ranking_rankingContainer__tpo6a {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.Ranking_menuItem__\\+Kwvq {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  border: 1px solid #000;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
}

.Ranking_menuPhoto__D-36U {
  width: 20%;
  height: auto;
  margin-right: 20px;
}

.Ranking_menuDetails__s25Jw {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.Ranking_menuName__HU7Cm {
  font-weight: bold;
  font-size: 1.2em;
}

.Ranking_menuScore__EqI8c {
  font-size: 1em;
  color: #555;
}

@media (max-width: 600px) {
  .Ranking_menuPhoto__D-36U {
    width: 30%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Ranking.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".rankingContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n  width: 100%;\n  box-sizing: border-box;\n  overflow-x: hidden;\n}\n\n.menuItem {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  max-width: 800px;\n  border: 1px solid #000;\n  margin-bottom: 10px;\n  padding: 10px;\n  background-color: #fff;\n  box-sizing: border-box;\n}\n\n.menuPhoto {\n  width: 20%;\n  height: auto;\n  margin-right: 20px;\n}\n\n.menuDetails {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  flex-grow: 1;\n}\n\n.menuName {\n  font-weight: bold;\n  font-size: 1.2em;\n}\n\n.menuScore {\n  font-size: 1em;\n  color: #555;\n}\n\n@media (max-width: 600px) {\n  .menuPhoto {\n    width: 30%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rankingContainer": `Ranking_rankingContainer__tpo6a`,
	"menuItem": `Ranking_menuItem__+Kwvq`,
	"menuPhoto": `Ranking_menuPhoto__D-36U`,
	"menuDetails": `Ranking_menuDetails__s25Jw`,
	"menuName": `Ranking_menuName__HU7Cm`,
	"menuScore": `Ranking_menuScore__EqI8c`
};
export default ___CSS_LOADER_EXPORT___;
