// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_homeContainer__mfaS8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.Home_homeContainer__mfaS8 h1 {
  margin-bottom: 20px;
}

.Home_navList__rncny {
  list-style: none;
  padding: 0;
}

.Home_navItem__G4gaV {
  margin: 10px 0;
}

.Home_navLink__kReJx {
  text-decoration: none;
  color: blue;
  font-size: 1.2em;
}

.Home_navLink__kReJx:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Home.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".homeContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 50px;\n}\n\n.homeContainer h1 {\n  margin-bottom: 20px;\n}\n\n.navList {\n  list-style: none;\n  padding: 0;\n}\n\n.navItem {\n  margin: 10px 0;\n}\n\n.navLink {\n  text-decoration: none;\n  color: blue;\n  font-size: 1.2em;\n}\n\n.navLink:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeContainer": `Home_homeContainer__mfaS8`,
	"navList": `Home_navList__rncny`,
	"navItem": `Home_navItem__G4gaV`,
	"navLink": `Home_navLink__kReJx`
};
export default ___CSS_LOADER_EXPORT___;
