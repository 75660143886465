// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReviewPage_menuPageContainer__yKWRe {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.ReviewPage_menuPageHeader__xIg-Z {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.ReviewPage_menuPageHeader__xIg-Z button {
  margin: 0 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.5em;
}

.ReviewPage_menuPageHeader__xIg-Z div {
  margin: 0 20px;
  font-size: 1.5em;
  font-weight: bold;
}

.ReviewPage_reviewForm__m8JTb {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  max-width: 600px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.ReviewPage_reviewForm__m8JTb select,
.ReviewPage_reviewForm__m8JTb input,
.ReviewPage_reviewForm__m8JTb textarea,
.ReviewPage_reviewForm__m8JTb button {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
  font-size: 1em;
  box-sizing: border-box;
}

.ReviewPage_sliderContainer__UzP3H {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.ReviewPage_slider__ZppEv {
  flex-grow: 1;
}

.ReviewPage_sliderValue__K3LlQ {
  margin-left: 10px;
  font-size: 1.5em;
}

.ReviewPage_reviewForm__m8JTb textarea {
  height: 100px;
  resize: none;
}

.ReviewPage_buttonDisabled__x97V7 {
  color: grey;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ReviewPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;;;;EAIE,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".menuPageContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n}\n\n.menuPageHeader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.menuPageHeader button {\n  margin: 0 10px;\n  cursor: pointer;\n  background: none;\n  border: none;\n  font-size: 1.5em;\n}\n\n.menuPageHeader div {\n  margin: 0 20px;\n  font-size: 1.5em;\n  font-weight: bold;\n}\n\n.reviewForm {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 95%;\n  max-width: 600px;\n  box-sizing: border-box;\n  overflow-x: hidden;\n}\n\n.reviewForm select,\n.reviewForm input,\n.reviewForm textarea,\n.reviewForm button {\n  margin-bottom: 10px;\n  width: 100%;\n  padding: 10px;\n  font-size: 1em;\n  box-sizing: border-box;\n}\n\n.sliderContainer {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  max-width: 600px;\n  box-sizing: border-box;\n  overflow-x: hidden;\n}\n\n.slider {\n  flex-grow: 1;\n}\n\n.sliderValue {\n  margin-left: 10px;\n  font-size: 1.5em;\n}\n\n.reviewForm textarea {\n  height: 100px;\n  resize: none;\n}\n\n.buttonDisabled {\n  color: grey;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuPageContainer": `ReviewPage_menuPageContainer__yKWRe`,
	"menuPageHeader": `ReviewPage_menuPageHeader__xIg-Z`,
	"reviewForm": `ReviewPage_reviewForm__m8JTb`,
	"sliderContainer": `ReviewPage_sliderContainer__UzP3H`,
	"slider": `ReviewPage_slider__ZppEv`,
	"sliderValue": `ReviewPage_sliderValue__K3LlQ`,
	"buttonDisabled": `ReviewPage_buttonDisabled__x97V7`
};
export default ___CSS_LOADER_EXPORT___;
