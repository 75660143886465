// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubmitMessage_submitMessage__7Bi30 {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/SubmitMessage.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,UAAU;EACV,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".submitMessage {\n  position: fixed;\n  bottom: 10px;\n  left: 10px;\n  background-color: black;\n  color: white;\n  padding: 10px;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitMessage": `SubmitMessage_submitMessage__7Bi30`
};
export default ___CSS_LOADER_EXPORT___;
